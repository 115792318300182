export const experiences = [{
    "heading": "Buckbox Infotech",
    "description": "Python Django Development",
    "role": "Software Developer",
    "duration": "July 2023 - Current",
    "extraInfo": [
      "Developed and maintained a high-volume payment aggregator system, processing 20-40k daily transactions for 600 to 1000 users.",
      "Led backend development team, ensuring smooth cross-team communication and project delivery.",
      "Managed end-to-end deployment of staging and production environments on AWS, including EC2, RDS, S3, and CloudFront.",
      "Orchestrated integrations with leading banks (HDFC, IDBI, Cosmos, Kotak) and payment gateways like PhonePe.",
      "Implemented alerting system for anomaly detection and integrated third-party solutions for security and verification.",
      "Engineered load testing scripts using JMeter and Selenium for performance optimization.",
    ],

  }, {
    "heading": "Fero.AI",
    "description": "Python Django Development",
    "role": "Software Developer SDE L2",
    "duration": "May 2022 - June 2023",
    "extraInfo": [
      "Contributed to frontend tasks, improving user experience and interface functionality.",
      "Engineered a Zone Recommendation Engine using K-Means clustering, optimizing driver assignments for 40-60 orders daily.",
      "Integrated third-party APIs for order assignment, ensuring smooth operational synchronization.",
      "Designed and implemented reporting system for monitoring client requirements and performance indicators.",
      "Led development of a ERP system used for used Electronics market Place",
    ],
  },
  
  {
    "heading": "Fero.AI",
    "description": "Python Django Development",
    "role": "Software Developer SDE L1",
    "duration": "Oct 2021 - April 2022",
    "extraInfo": [
      "Developed and maintained ERP systems tailored for logistics, optimizing operations and enhancing efficiency.",
"Integrated client-side APIs with Microsoft Warehouse Management System for seamless data exchange.",
"Developed APIs for mobile apps, enabling real-time order tracking for drivers, enhancing customer service.",
    ],
  }, 

  ]
  
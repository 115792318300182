export const educations = [{
  "heading": "Bachelors in Engineering",
  "description": "L.D.R.P Institute of Technology & Research",
  "role": "Electrical Engineering",
  "duration": "Aug 2015 - May 2019"

}, {
  "heading": "12th HSC",
  "description": "M.G Hr Sec School",
  "role": "Science Stream (Physics/Chem/Math)",
  "duration": "Aug 2014 - May   2015"
}, {
  "heading": "10th SSC",
  "description": "K.G.K Hr Sec School",
  "role": " - ",
  "duration": "Aug 2012 - May 2013"
},

]